<template>
  <div class="local minbox">
    <!-- 头部通用区域 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!--    banner-->
    <section class="solution-banner">
      <div class="main-container">
        <div class="inner">
          <div class="title1">
            产融合作信息化平台
          </div>
          <div class="title2">
            Industrial Finance & Information Platfrom
          </div>
        </div>
      </div>
    </section>

    <section class="main-container">
      <div class="section-name">
        信息化平台介绍
      </div>

      <div class="section-des txt-center">
        为响应政府促进金融服务制造强国建设，华炫鼎盛打造了服务于国家产融合作的信息与交易平台，降低金融机构融资风险、提升金融机构放贷意愿，助力金融服务实体经济。
      </div>
      <section class="lists">
        <div class="item">
          <img src="../../assets/solutionImg/infor1.png" alt="">
          <div>
            <div class="item-name">
              建立产融合作标准
            </div>
            <div class="item-des">
              在工信部的指导下，平台提供产融政策管理，并制定融资合作标准、规格与模式，地方经信委邀请金融机构在该标准框架之下参与产融合作，并推介受扶持企业、鼓励当地金融机构给予融资。
            </div>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/solutionImg/infor2.png" alt="">
          <div>
            <div class="item-name">
              进行绩效管理
            </div>
            <div class="item-des">
              融资金流在产融平台上划转，让政府部门确实掌握真实资金发生额与资金流向，并能监管与风控，然后通过BI分析，让相关部门了解产融合作规模、进展、行业分布、地域分布与财政资金撬动地方资金等信息，作为优化产融政策的参考；
            </div>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/solutionImg/infor3.png" alt="">
          <div>
            <div class="item-name">
              将政策、产业与金融三者对接
            </div>
            <div class="item-des">
              通过人工智能的语义理解技术实现智能化产融合作政策梳理与协调，运用推荐系统实现政策与被扶持企业的匹配。
            </div>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/solutionImg/infor4.png" alt="">
          <div>
            <div class="item-name">
              降低金融机构的融资风险
            </div>
            <div class="item-des">
              运用大数据与机器学习对融资企业进行智能征信以降低金融机构的融资风险。
            </div>
          </div>
        </div>
      </section>
    </section>

    <section class="fund">
      <div class="section-name">
        产融合作信息化平台应用架构图
      </div>
      <div class="tra-img">
        <img src="../../assets/solutionImg/information_img_nor.png" alt="">
      </div>
      <div class="section-name subname">
        产融平台通过大数据+人工智能为一、二、三产业进行金融赋能
      </div>
    </section>

    <section class="dui-jie">
      <div class="section-name">
        金融平台对接模式
      </div>
      <div class="section-des dui-jie-width mb30">
        产融合作信息化平台提供金融服务通道，积极与地方政府或金融机构的普惠金融平台对接，对接模式如下：
      </div>
      <section class="section-des  dui-jie-width">
        <div class="mb20">
          <span class="num">1</span><span class="w439">产融合作信息化平台提供政策信息、政策画像给各金融服务平台。</span>
        </div>
        <div  class="mb20">
          <span class="num">2</span><span class="w439">产融合作信息化平台对采集的企业信息化政策匹配并反馈匹配结果，如：符合某某政策的支持，需要通过某些担保公司进行担保，但担保产生的成本可通过财政补贴的扶持办法给予企业减免等。</span>
        </div>
        <div class="mb20">
          <span class="num">3</span><span class="w439">以数据提升企业资信水平、金融机构要进行融资前，可通过产融合作信息化平台了解该企业的征信情况，配合金融机构内部的风控手段做出是否给予融资政策。</span>
        </div>
        <div class="mb20">
          <span class="num">4</span><span class="w439">融资前，融资款可通过产融合作信息化平台发放，产融合作信息化平台可监督资金应用并提供后续的贷后管理与风险提示。</span>
        </div>
      </section>
    </section>

    <section class="ren-gong">
      <div class="main-container">
        <div class="section-name">
          人工智能技术
        </div>
        <div class="mb40">
          2018年，华炫鼎盛依托于多年的经验和积累，承接了工信部人工智能与实体经济深度融合课题，运用人工智能、大数据、区块链等科技手段，赋能政府、金融机构和各企业，最终实现银政企产融生态创新发展模式。为金融资本空转、经济脱实向虚、金融服务效率低下等深层次问题提供了解决路径。
        </div>
        <div>
          产融合作信息化平台作为金融机构、企业及地方政府政策和资金的桥梁。产融平台应用政策画像、企业画像、智能筛选、智能征信、智能定价、债权追踪等六大人工智能技术，实现了政策及资金的精准对接及风险的智能化管理，实现了一二三产业融通发展的新模式。
        </div>
        <img src="../../assets/solutionImg/artificialtechnology_img.png" alt="">
      </div>

    </section>

    <!-- 底部公共区域 -->
    <section class="boxContentFooter">
      <FooterAll />
    </section>
  </div>
</template>
<script>
  import Navbar from '../../components/public/NavBar'
  import FooterAll from '../../components/public/footerAll'
  export default {
    data(){
      return{
        activeIndex: '1'
      }
    },
    components: {
      Navbar,
      FooterAll,
    },
  }
</script>
<style lang="scss" scoped>
  @import "./solution.scss";
</style>
<style lang="scss" scoped>
  .subname{
    margin-top: 20px
  }
  .mb40{
    margin-bottom: 40px;
  }
  .mb20{
    margin-bottom: 20px;
    display: flex;
    align-items: start;
  }
  .dui-jie-width.mb30{
    margin-bottom: 30px;
  }
  .num{
    width: 20px;
    height: 20px;
    background: #4A8ABE;
    border-radius: 50%;
    display: inline-block;
    margin-right: 25px;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 20px;
    text-align: center;
    flex: none;
    position: relative;
    top: 4px;
  }
  .dui-jie-width{
    width: 674px;
    margin: 0 auto;
  }
  .dui-jie{
    padding-bottom: 42px;
  }
  .show-img{
    width: 1016px;
    height: 675px;
    margin: 40px auto 60px;
    img{
      width: 100%;
    }
  }
  .local{
    .solution-banner{
      background-image: url("../../assets/solutionImg/information_banner.png");
    }
  }
  .ren-gong{
    overflow: hidden;
    background-position: 50%;
    height: 939px;
    background-size: cover;
    background-image: url("../../assets/solutionImg/platform_service.png");
    font-size: 14px;
    color: #333333;
    line-height: 24px;
    img{
      display: block;
      width: 1020px;
      height: 540px;
      margin: 60px auto ;
    }
  }
  .fund{
    overflow: hidden;
    background-position: 50%;
    height: 871px;
    background-size: cover;
    background-image: url("../../assets/solutionImg/fund-bus.png");
    .section-name{
      margin-bottom: 21px;
    }
    .tra-img{
      width: 1218px;
      height: 625px;
      margin: 0 auto;
      img{
        width: 100%;
      }
    }
  }
</style>
